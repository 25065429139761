.UspBlock {
    padding: var(--skeleton-block-default-padding);
}

.Usps {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    gap: 1rem;
    margin-top: 3rem;
}

.Usp {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.Usp__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0;
    justify-content: center;
    text-align: center;
}

.Usp__image figure {
    max-width: fit-content;
}

.Usp__image figure img {
    height: 3rem;
    width: auto;
}

.Usp__title {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: 500;
}

.Usp__description {
    margin: 0;
    display: inline;
}

.Usp__arrow {
    font-size: inherit;
    transition: 0.3s transform;
    line-height: inherit;
    vertical-align: bottom;
}

.Usp:hover .Usp__arrow {
    transform: translateX(4px);
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

@media (min-width: 768px) {
    .Usps {
        flex-direction: row;
    }

    .Usp__description {
        margin: 0;
    }
}
