.webmap {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.webmap__controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    padding: 0.5rem 15px;
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
}

.webmap__toggleviewbutton {
    margin-left: auto;
}

header[data-open='true'] + div .webmap__participatebutton {
    z-index: 1;
}

.webmap__participatebutton {
    position: absolute;
    left: 50%;
    bottom: 5rem;
    translate: -50%;
    z-index: 9;
}

/* .webmap__responselist__button {
    position: absolute;
    right: 0%;
    top: 40px;
    z-index: 9;
} */

.webmap__responses {
    flex: 1 0 50%;
}

.webmap__esrimap {
    position: relative;
    flex: 1 0 50%;
}

.webmap__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
}

.esrimap {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.container {
    display: inline-block;
    padding: 0 4px 0 0;
}

.poimarker {
    background-color: white;
    display: inline-block;
    color: var(--default-secondary);
    white-space: nowrap;
    position: relative;
    padding: 0.5rem 1rem;
    width: auto;
    font-size: 2rem;
    border: 1px solid var(--default-secondary);
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.555);
}

.poimarker::after {
    content: '';
    position: absolute;
    left: 1.5rem;
    border: 1.5rem solid transparent;
    border-top-color: rgb(255, 255, 255);
    top: 100%;
}

.poimarker::before {
    /* //It's possible to uncomment this, the shadow below a pointmaker will comeback. */
    content: '';
    left: 1.5rem;
    display: block;
    position: absolute;
    top: calc(100% + 1.5rem);
    margin-top: -0.5rem;
    height: 1rem;
    width: 3rem;
    background: #ccc;
    background: radial-gradient(
        ellipse farthest-side,
        rgba(0, 0, 0, 0.45) 0,
        transparent 100%
    );
}

.poimarker__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.poimarker__svg {
    width: 3rem;
    height: 3rem;
    /* background: yellow; */
    border-radius: 50%;
    /* border: 1px solid var(--default-dark); */
    color: var(--default-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.poimarker__svg svg {
    height: 100%;
    width: 100%;
    scale: 0.7;
}

.poimarker__inner svg path {
    fill: currentColor;
}

.esrimap__temporaryMarkers {
    pointer-events: none;
    position: absolute;
    opacity: 0;
    max-width: 100%;
    overflow: hidden;
}

.listwidget__container {
    max-height: inherit;
    overflow-y: auto;
}

.listwidget__list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 100%;
    overflow-y: auto;
    background: var(--default-light);
}

.listwidget__list li {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--default-dark);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    text-overflow: ellipsis;
}

.listwidget__list li * {
    color: var(--default-primary);
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}

.listwidget__list li .listwidget__list__link {
    border-color: transparent;
}

.webmap__dialog {
    /* position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.9);
    border: 0; */

    z-index: 9;
    width: 90%;
    height: 90%;
    max-width: 700px;
    flex-direction: column;
    border: -1px solid var(--default-dark);
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
}

.webmap__dialog__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.5rem 0;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;
}

.webmap__dialog__header h3 {
    margin: 0;
}

.webmap__dialog__header_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.webmap__dialog__header_title h4 {
    color: var(--default-secondary);
    font-size: 1.8rem;
    margin-bottom: 0;
}

.webmap__dialog__header__icon {
    cursor: pointer;
    color: var(--default-secondary);
    margin-left: 0.25rem;
    line-height: inherit;
}

.webmap__dialog__header__icon:hover,
.webmap__dialog__header__icon:focus {
    color: var(--default-dark);
}

.webmap__dialog__header__icon:focus {
    pointer-events: none;
}

.webmap__dialog__header__tooltipwrapper:focus .webmap__dialog__header__tooltip,
.webmap__dialog__header__tooltipwrapper:focus-within
    .webmap__dialog__header__tooltip {
    display: block;
}

.webmap__dialog__header__tooltip {
    display: none;
    background-color: rgb(233, 231, 231);
    padding: 1.5rem;
    z-index: 3;
    box-shadow: 0 1px 2px rgb(0 0 0 / 16%);
    position: absolute;
    top: 100%;
    right: 0;
}

.webmap__dialog__header__tooltip:focus {
    display: block;
}

.webmap__dialog__header__tooltip p {
    font-size: 0.9rem;
    line-height: 1.5rem;
}

.webmap__dialog__inner {
    padding: 0rem;
    background-color: #fff;
    /* overflow-y: auto; */
}

.webmap__dialog--success {
    max-height: 200px;
}

@media (min-width: 768px) {
    .webmap__participatebutton {
        bottom: unset;
        top: 2rem;
    }
}

.webmap__dialog__responseList__inner {
    padding: 0 1rem 1rem 1rem;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}

.webmap__dialog__responseList[open]
    + .webmap__dialog__responseList__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    z-index: 0;
}

@media (min-width: 1024px) {
    .webmap__dialog__responseList {
        position: relative;
        margin-right: auto;
        top: 0;
        width: 30%;
        height: 100%;
        overflow: hidden;
        padding: 0;
        box-shadow: -5px 0 6px rgb(0 0 0 / 16%);
    }

    .webmap__dialog__responseList__inner {
        position: absolute;
    }

    .webmap__dialog__responseList__background {
        display: none;
    }
}

.bookmarkwidget {
    display: flex;
    flex-direction: row-reverse;
    gap: 0.5rem;
    pointer-events: auto;
    order: 9;
    margin-bottom: 10px;
    box-shadow: none !important;
}

.bookmarkwidget__button {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.bookmarkwidget--hidden {
    display: none;
}

.bookmarklist {
    display: none;
}

.bookmarklist--open {
    display: block;
}

.bookmarkwidget__list li:has(button[aria-current='location']) {
    background-color: var(--default-primary);
}

.bookmarkwidget__list li:has(button[aria-current='location']) button {
    color: var(--default-light);
}

@media (min-width: 768px) {
    .bookmarkwidget__button {
        display: none !important;
    }

    .bookmarklist {
        display: flex;
        position: fixed;
        bottom: 1rem;
        width: 100%;
        justify-content: center;
        left: 0;
    }

    .bookmarkwidget__list {
        display: flex;
        position: fixed;
        bottom: 1rem;
        max-width: 100%;
        justify-content: center;
        background-color: transparent;
        flex-wrap: wrap;
        gap: 0.25rem;
    }

    .bookmarkwidget__list li {
        background-color: var(--default-light);
        border: 1px solid var(--default-dark);
        max-width: 100%;
    }

    .bookmarkwidget__list li:hover {
        background-color: var(--default-secondary);
    }
}
