.pollBlock {
    margin: 0 auto;
    width: 100%;
    padding: 2rem 1rem;
}

.pollBlock__column {
    padding: 0;
}

.pollBlock h2 {
    margin-bottom: 20px;
}

.pollBlock__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.pollBlock__answers {
    border: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0;
}

.pollBlock__column .pollBlock__answers {
    grid-template-columns: repeat(1, 1fr);
}

.pollBlock__label {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid var(--wb-gray-900);
    border-radius: 2px;
    cursor: pointer;
    outline: 2px solid transparent;
    transition: 0.3s;
}

.pollBlock__label__inputrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    margin-top: auto;
    margin-right: auto;
}

.pollBlock__label:hover,
.pollBlock__label:has(input:checked) {
    border-color: var(--wb-blue-600);
    outline: 2px solid var(--wb-blue-600);
}

.pollBlock__results {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.pollBlock__results__answer {
    display: flex;
    flex-direction: column;
}

.pollBlock__results__answer__label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    gap: 1rem;
}

.pollBlock__results__answer__label > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pollBlock__results__answer__label > small {
    white-space: nowrap;
    font-weight: bold;
}

.pollBlock__results__answer__progress {
    width: 100%;
}

.pollBlock__results__sumvotes {
    align-self: flex-end;
}

.pollBlock__results__answer__progressbar {
    width: 100%;
    height: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
    background-color: silver;
    position: relative;
    background: linear-gradient(to right, #95cfec, #017ab6);
}

.pollBlock__results__answer__progressbar__inner {
    position: absolute;
    right: 0;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: var(--wb-gray-200);
    animation: progressBar 0.5s ease-in-out;
    animation-fill-mode: forwards;
    box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.2);
}

/* .pollBlock__results__answer__progressbar__fill {
    display: block;
    height: 100%;
    ;
    background-color: var(--wb-blue-600);

    animation-fill-mode: both;
} */

@keyframes progressBar {
    0% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}
