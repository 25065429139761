.ListItemPage__keyvisual {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 12.5rem;
    position: relative;
}

.ListItemPage__backButton {
    position: absolute;
    top: 1rem;
    left: 1rem;
}

@media screen and (min-width: 768px) {
    .ListItemPage__keyvisual {
        height: 18.75rem;
    }
}
