.PoiPage {
}

.PoiPage__map {
    position: relative;
    width: 100%;
    height: 200px;
    cursor: default;
}

.PoiPage__esrimap {
    pointer-events: none;
    position: relative;
    width: 100%;
    height: 200px;
    cursor: default;
}

.PoiPage__map__back {
    z-index: 1;
    pointer-events: auto;
    position: absolute;
    top: 2rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.PoiPage__map__participate__footer {
    margin-top: 2rem;
    padding: 0.5rem;
    position: sticky;
    bottom: 0rem;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 -3px 3px rgb(0 0 0 / 16%);
}

.PoiPage__map__participate {
    margin: 0 auto;
}

.PoiPage__responseList {
    max-width: 768px;
}

.PoiPage__responseList__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    margin-bottom: 1rem;
}

.PoiPage__responseList__header h2 {
    margin: 0;
}

.PoiPage__tooltipwrapper {
    position: relative;
}

.webmap__dialog {
    z-index: 9;
    width: 90%;
    height: 90%;
    max-width: 700px;
    flex-direction: column;
    border: -1px solid var(--default-dark);
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
}

.webmap__dialog__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;
}

.webmap__dialog__header h3 {
    margin: 0;
}

.webmap__dialog--success {
    max-height: 200px;
}

.webmap__dialog__inner {
    padding: 0rem;
    background-color: #fff;
    /* overflow-y: auto; */
}
