.webscene {
    position: relative;
    display: flex;
    flex-direction: row;
    background: var(--wb-white);
    height: 100%;
}

.main_container {
    display: flex;
    /* height: 50rem; */
    width: 100%;
    background: var(--default-primary);
}

.main_loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
}

.main_standardscreen {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.main_left {
    display: flex;
    align-items: center;
    order: 1;
    padding: 2rem 0;
}

.main_left_title {
    color: var(--default-light);
    font-weight: 300;
}

.main_left_description {
    color: var(--default-light);
}

.main_left_date {
    color: var(--default-light);
}

.webscene .main_right {
    position: relative;
    background: var(--default-light);
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -3px 3px 5px rgba(87, 87, 87, 0.1);
    transition: all 0.5s ease-in-out;
    border: none;
    padding: 1rem;
}

.main_expandedscreen .main_right {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--default-light);
}

.main_right_dialog {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    padding: 0;
    border: 0;
}

.main_right_esriscene_small {
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--default-primary);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}

.main_right_content {
    height: 100%;
    width: 100%;
    background: var(--default-primary);
    display: flex;
    flex-direction: column;
}

.main_esriscene {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: var(--wb-white);
}

.main_esriscene_menubar {
    display: flex;
    position: absolute;
    justify-content: space-between;

    width: calc(100% - 30px);
    max-width: 45rem;

    height: 2.8rem;
    left: 50%;
    transform: translateX(-50%);

    background: var(--default-light);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.8);
    opacity: 0;
    bottom: -4rem;
}

.main_expandedscreen .main_esriscene_menubar {
    opacity: 1;
    transition-delay: 0.2s;
    bottom: 25px;
    transition: bottom 0.3s ease-in-out;
}

.main_esriscene_menubar_info {
    width: 4rem;
    /*background-color: white;*/
    border: none;
    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

.main_esriscene_menubar_icon {
    color: var(--default-primary);
    font-size: 24px;
    font-weight: bolder;
    background-color: var(--default-light);
}

.main_esriscene_menubar_info_pressed {
    background-color: var(--default-primary);
    color: #d93333;
}

.main_esriscene_menubar_icon_pressed {
    color: var(--default-light);
    background-color: var(--default-primary);
}

.main_esriscene_menubar_arrow {
    width: 2rem;
    /*background-color: white;*/
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_esriscene_menubar_buttonrow {
    width: 80%;
    background-color: var(--default-light);
    /*box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);*/
    overflow-x: scroll;
    white-space: nowrap;
}

.main_esriscene_menubar_buttonrow::-webkit-scrollbar {
    display: none;
}

.main_menubar_activePOV {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    width: auto;
    height: 100%;
}

.main_menubar_activePOV_pressed {
    background-color: var(--default-primary);
    color: white;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.main_esriscene_responseList {
    position: absolute;
    overflow: auto;
    width: 0;
    height: 0;
    opacity: 0;
    bottom: 0;
    right: 0;
    transition: all 0.3s ease-in-out;

    background-color: rgba(255, 255, 255, 1);
}

.main_responselistopen .main_esriscene_responseList {
    height: 47%;
    width: 100%;
    opacity: 1;
    padding: 0.5rem;
    z-index: 10;
    /*box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.3);*/
}

.main_esriscene_resonseList_content {
    flex: 1;
    overflow: auto;
}

.main_esriscene_resonseList_hidden {
    opacity: 0;
    visibility: hidden;
}

.webmap__participatebutton {
    position: absolute;
    left: 50%;
    bottom: 5rem;
    translate: -50%;
    z-index: 9;
}

.main_esriscene_infoBox {
    padding: 35px;
    background: var(--default-primary);
    position: absolute;

    left: 1%;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-50%);
    transition:
        width 0.5s ease-in-out,
        height 0.6s ease-in-out;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 40%;
    top: 65%;
    width: 98%;
    overflow: auto;
}

.main_esriscene_infoBox_hidden {
    opacity: 0;
    visibility: hidden;
}

.notification_content {
    display: flex;
    align-items: center;
}

.notification_button {
    margin-left: auto;
    cursor: pointer;
    padding-left: 1rem;
}

.contentBlock {
    display: block;
}

.webmap__successnotification {
    z-index: 10;
}

.esriscene__temporaryMarkers {
    pointer-events: none;
    position: absolute;
    opacity: 0;
    max-width: 100%;
    overflow: hidden;
}

/*overwrite styling specific for poi pages*/

.PoiPage .main_right {
    padding: 0;
}

.fullscreenbutton[data-expanded='false'] {
    width: auto !important;
    gap: 0.5rem;
}

.main_right_openFullScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 0.3s all;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
}

.main_right_openFullScreen ~ .main_right_content canvas {
    transition: 0.3s all;
}

.main_right_openFullScreen:focus ~ .main_right_content canvas,
.main_right_openFullScreen:hover ~ .main_right_content canvas {
    opacity: 0.8;
}

@media (min-width: 768px) {
    .main_esriscene_infoBox {
        top: 50%;
        width: 45%;
        height: auto;
    }

    .main_left {
        order: inherit;
        flex: 1;
    }

    /* if no left column, make the scene page wide */
    .main_right {
        /* aspect-ratio: 32 / 9; */
        flex: 1;
    }

    .PortalPage3d .main_right {
        aspect-ratio: 32 / 9;
    }

    .PortalPage3d .main_left + .main_right {
        aspect-ratio: 16 / 9;
    }

    .main_responselistopen .main_esriscene_responseList {
        width: 30%;
        height: 100%;
        position: relative;
        transition:
            width 0.3s ease-in-out,
            opacity 0.3s ease-in-out;
    }

    .main_standardscreen {
        flex-direction: row;
        padding-bottom: 3rem;
        gap: 2rem;
    }

    .PoiPage .main_standardscreen {
        padding-bottom: 0;
    }

    .main_right_content {
        flex-direction: row;
    }

    .option1 {
        display: none;
    }

    .webmap__participatebutton {
        bottom: 2rem;
    }

    .main_expandedscreen .webmap__participatebutton {
        bottom: unset;
        top: 2rem;
    }
}
