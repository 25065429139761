.responselist__summary {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0.5rem 0;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.responselist__details[open] .icons {
    rotate: 180deg;
}

.responselist__mood {
    font-size: 1.25rem;
}

.responselist__badge {
    border: 1px solid;
    color: #005d76;
    background-color: #fff;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0;
}

.responseItem {
    font-weight: 400;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    align-items: stretch;

    overflow-y: auto;
}

.responseItem * {
    pointer-events: none;
}

.responseItem a {
    pointer-events: all;
}

.responseItem--hidden {
    opacity: 0.5;
}

.responseItem--selected {
    border-color: #005d76;
}

.responseItem__title {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    font-weight: 700;
}

.responseItem__title--response {
    margin-top: 0.5rem;
    padding-top: 1rem;
    border-top: 1px solid #fff;
}

.responseItem__Location {
    color: var(--default-secondary);
    padding: 0 1rem;
}

.responseItem__clientBlock {
    width: 80%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    padding: 1rem;
    color: var(--default-primary);
    background-color: rgb(233, 231, 231);
    box-shadow: 0 1px 2px rgb(0 0 0 / 16%);
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.responseItem_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.responseItem__name {
    /* height: 2rem; */
    display: block;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* font-size: 1.2rem; */
}

.responseItem__date {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    text-align: right;
    vertical-align: bottom;
    font-size: 0.7rem;
    color: currentColor;
}

.responseItem__quote {
    font-size: 0.9rem;
    margin-bottom: 0;
    word-break: break-word;
    hyphens: auto;
}

.responseItem__quote * {
    margin-bottom: 0;
    font-size: 0.9rem;
}

.responseItem__quote span {
    padding-right: 0.5rem;
    line-height: 1.4;
}

.responseItem__quote img {
    display: inline;
    height: 1rem;
    margin-right: 0.5rem;
}

.responseItem__triangle_left {
    width: 0;
    height: 0;
    border: 1rem solid transparent;
    border-top: 1.1rem solid rgb(233, 231, 231);
    border-bottom: 0;
    position: absolute;
    top: 100%;
}

.responseItem__ownerBlock {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    position: relative;
    padding: 1rem;
    color: var(--default-light);
    background-color: var(--default-secondary);
    box-shadow: 0 1px 2px rgb(0 0 0 / 16%);
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.responseItem__triangle_right {
    width: 0;
    height: 0;
    border: 1rem solid transparent;
    border-top: 1.1rem solid var(--default-secondary);
    border-bottom: 0;
    position: absolute;
    top: 100%;
    right: 1rem;
}

.responseItem__lineDivider {
    border-bottom: 1px solid #aaaaaa62;
    width: 100%;
    margin: 0.5rem 0;
}
