.imageBlock {
    padding: var(--skeleton-block-default-padding);
}

.imageBlock__container {
    display: flex;
    justify-content: center;
}

.image {
    max-width: 100%;
    height: auto;
}
