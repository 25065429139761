li:has(> .languageButton) {
    display: flex;
    flex-direction: row;
}

.languageButton:hover {
    color: var(--default-primary);
    text-decoration: underline;
}

.languageDivider {
    padding: 0;
    border: none;
    border-right: 1px solid;
    height: 1rem;
    margin: 0 0.5rem;
}

.skiplink {
    width: auto;
}

.language ul {
    align-items: center;
}

.language li {
    padding: 0 0.5rem;
    border-right: 1px solid #000;
}

.language li:last-child {
    border-right: none;
}

.language [aria-current='true'] {
    color: var(--default-primary);
    text-decoration: underline;
}
