.searchBar__form {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0;
}

.searchBar__input {
    /* width: 0; */
    min-width: 0;
    border: none;
    border-bottom: 1px solid var(--wb-gray-900);
    outline: none;
    transition: min-width 0.4s cubic-bezier(0, 0.795, 0, 1);
    padding: 0;
    flex: 1;
}

.searchBar__form:focus-within {
    .searchBar__input {
        min-width: 13rem;
    }
}

.searchBar__icon {
    font-size: 1.5rem;
    color: var(--wb-gray-900);
    cursor: pointer;
    padding: 0.4rem;
}

.searchBar__icon:hover {
    color: var(--default-primary);
}

.searchBar--searchPage {
    width: 60%;

    .searchBar__input {
        width: 70%;
    }
}

.header--search {
    order: -1;
}

@media (min-width: 1024px) {
    .searchBar__input {
        width: 0;
        flex: unset;
    }

    .header--search {
        order: unset;
    }
}
