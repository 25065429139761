.searchPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--skeleton-block-default-padding);
}

.searchPage__results {
    margin: 2rem 1rem;
    padding: 0 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.searchPage__noResults {
    margin-top: 2rem;
    font-size: 1.5rem;
}

.searchPage__result {
    border: 1px solid var(--wb-gray-900);
    padding: 1.25rem 1.875rem;
    margin-bottom: 1.5rem;
    width: 100%;
    position: relative;
    color: var(--default-dark);
}

.searchPage__result:hover {
    background-color: var(--default-secondary);
    color: var(--default-dark);
    cursor: pointer;
}

.searchPage__result::after {
    font-family: 'Material Icons';
    content: 'arrow_forward';
    color: #000;
    position: absolute;
    bottom: 0.25rem;
    right: 1.25rem;
    transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
    .searchPage__results {
        width: 60%;
    }
}
