.iframeContainer {
    margin: 0 auto;
    padding: var(--skeleton-block-default-padding);
}

.iframeBlock {
    width: 100%;
    height: 100%;
    max-width: 100%;
}
