.collapsibleItem {
    --wb-collapsible-summary-color: var(--default-primary);
    --wb-collapsible-summary-bgcolor: var(--default-light);
    --wb-collapsible-hyperlink-color: var(--default-primary);

    --wb-collapsible-bordercolor: var(--default-dark);

    display: block;
    width: 100%;
}
