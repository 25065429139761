.videoBlock {
    padding: var(--skeleton-block-default-padding);
}

/* this container is for showing videos as a standalone block */
.videoBlock__container {
}

.embeddedVideo {
    aspect-ratio: 16 / 9;
}

.video {
}
