.ListPreviewBlock {
    display: block;
    padding: var(--skeleton-block-default-padding);
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.ListPreviewBlock ul {
    list-style-type: none;
    margin: 0;
}

.ListPreviewBlock__ListItem {
    margin-bottom: 2rem;
    flex: 0 0 100%;
}
