.ButtonListBlock {
    padding: var(--skeleton-block-default-padding);
}

.ButtonListBlock__inner {
    margin-bottom: 2rem;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
