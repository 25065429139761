.divider {
    position: relative;
    z-index: 2;
    color: var(--default-primary);
}

.primary {
    color: var(--default-primary);
}

.secondary {
    color: var(--default-secondary);
}

.light {
    color: var(--default-light);
}

.dark {
    color: var(--default-dark);
}

.divider::before {
    content: '';
    position: absolute;
    top: -1.5rem;
    width: 0.5rem;
    height: 3rem;
    background-color: currentColor;
}

.divider::after {
    content: '';
    position: absolute;
    top: -0.25rem;
    width: 1.5rem;
    height: 0.5rem;
    background-color: currentColor;
}

.left::before {
    left: 0;
}

.left::after {
    left: 0rem;
}

.right::before {
    right: 0;
}

.right::after {
    right: 0rem;
}
