.ResponseDialog {
    border-radius: 5px;
    padding: 1.3rem;
    border: -1px solid var(--default-dark);
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
    width: 90%;
    max-width: 1000px;
}

.ResponseDialog button {
    cursor: pointer;
}

.ResponseDialog:not(:has(.ResponseDialog__minimap)) {
    max-width: 600px;
}

.ResponseDialog__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 99;
    justify-content: space-between;
}

.ResponseDialog__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1 1 100%;
    /* padding: 0.5rem 0; */
}

.ResponseDialog__title {
    margin: 0;
    font-size: 2rem;
    line-height: unset;
    color: rgb(10, 117, 194);
}

.ResponseDialog__modaltext {
    margin: 0;
}

.ResponseDialog__form {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    order: 2;
    width: 100%;
}

.ResponseDialog__minimap {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
}

.ResponseDialog__BottomRow {
    display: flex;

    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-grow: 1 1;
}

.ResponseDialog__BottomRow button,
.ResponseDialog__BottomRow input[type='submit'] {
    flex-basis: 50%;
}

.ResponseDialog__inputfield {
    padding: 0.6rem;
    border: 1px solid;
    border-color: var(--wb-gray-900);
}

.ResponseDialog__customradio:has(input:focus),
.ResponseDialog__inputfield:focus {
    outline: none;
    border: 1px solid;
    border-color: var(--wb-blue-600);
    outline: 2px solid var(--wb-blue-600);
    /* box-shadow: 0 1px 6px rgb(0 0 0 / 16%); */
}

.ResponseDialog__inputfield:hover {
    outline: none;
    border: 1px solid;
    border-color: var(--wb-blue-600);
    outline: 2px solid var(--wb-blue-600);
}

.ResponseDialog__checkbox[aria-invalid='true']:invalid,
.ResponseDialog__inputfield[aria-invalid='true']:invalid {
    border-color: var(--wb-signal-red);
}

.ResponseDialog [data-errortext] {
    color: var(--wb-signal-red);
    font-size: 0.8rem;
}

.ResponseDialog [data-errortext]:empty {
    display: none;
}

.ResponseDialog__locationTitle {
    padding-bottom: 0.5rem;
}

.ResponseDialog__customradio input[type='radio'] {
    position: absolute;
    width: 0;
    height: 0;
}

.ResponseDialog__inputgroup {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.ResponseDialog__customradio {
    /* The style can be changed back here to the figma design */
    display: flex;
    align-items: center;
    gap: 0.3rem;
    flex: 1;
    justify-content: center;
    height: 3rem;
    background-color: rgba(10, 117, 194, 0.082);
    border: 2px solid transparent;
}

.ResponseDialog__customradio:has(input) {
    color: rgb(10, 117, 194);
}

.ResponseDialog__customradio:has(input:checked) {
    color: rgb(255, 255, 255);
    background-color: rgb(10, 117, 194);
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
}

.ResponseDialog__inputgroup--hidden {
    display: none;
}

.ResponseDialog__submitbutton {
    display: flex;
    transition: background-color 0.3s ease;
    padding: 0.5rem;
    border: none;
    background-color: rgb(10, 117, 194);
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
}

.ResponseDialog__fieldset {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    border: 0;
    margin: 0;
}

& .dialog__map {
    padding: 0.5rem;
    flex: 1 0 50%;
}

.ResponseDialog__checkbox {
    cursor: pointer;
}

.ResponseDialog__minimap__inner {
    flex: 1 1 100%;
    position: relative;
    width: 100%;
    min-height: 120px;
    display: block;
    margin-bottom: 1rem;

    border-radius: 3px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
}

.topDiv {
    text-align: center;
}

.bottomDivsContainer {
    display: flex;
    justify-content: space-between;
}

.bottomDiv {
    width: 50%;
}

.miniMap {
    pointer-events: none;
}

.esri-component esri-attribution esri-widget .esri-attribution {
    background-color: yellow;
}

.ResponseDialog__searchWidget,
.esri-search {
    font-family:
        Roboto,
        Segoe UI,
        -apple-system,
        BlinkMacSystemFont,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif !important;
    width: 100% !important;
    border: 1px solid;
    border-color: var(--wb-gray-900);
}

.ResponseDialog__searchWidget:hover,
.ResponseDialog__searchWidget:has(input:focus),
.ResponseDialog__searchWidget:has(input[aria-expanded='true']) {
    border-color: var(--wb-blue-600);
    outline: 2px solid var(--wb-blue-600);
}

.ResponseDialog__searchWidget .esri-input {
    border: none;
}

.ResponseDialog__searchWidget--hidden {
    display: none !important;
}

.ResponseDialog__highlight {
    background-color: yellow;
}

.ResponseDialog_warning {
    color: var(--wb-signal-red);
}

@media screen and (min-width: 768px) {
    .ResponseDialog__inner {
        gap: 1rem 2rem;
    }

    .ResponseDialog__form {
        order: unset;
    }

    .ResponseDialog__form {
        flex: 1 0 65%;
    }

    .ResponseDialog__minimap {
        flex: 1 1 30%;
    }

    .ResponseDialog__minimap__inner {
        margin-bottom: 0;
    }
}
