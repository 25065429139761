.Footer {
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: auto;
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
    --wb-footer-text-color: var(--default-dark);
    --wb-footer-hyperlink-color: var(--default-primary);

    color: var(--wb-footer-text-color);
    background-color: var(--default-light);
}

.Footer nav,
.Footer ul {
    display: flex;
    margin: 0;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    list-style-type: none;
}

.Footer a {
    text-decoration: underline;
    color: var(--wb-footer-hyperlink-color);
}
