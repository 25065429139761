.timeline_block {
    padding: var(--skeleton-block-default-padding);
    margin: 0 auto;
    width: 100%;
}

.timeline_block__inner {
    margin-bottom: 2rem;
}

.timelineinfobox {
    flex: 0 1 100%;
    padding: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
}

.timeline_label {
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 2rem;
    order: -1;
}

.timeline_title {
    font-size: 2rem;
    color: var(--wb-blue-700);
}

.timeline_text {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: var(--wb-base-black);
}

.timelineiconbox {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    order: 2;
}

.line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 3px;
    height: 100%;
    background-color: var(--default-secondary);
}

.timelinerow {
    display: flex;
    flex-direction: row-reverse;
    gap: 2rem;
}

.timelinerow[data-type='active'] .timelineiconbox:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 3px;
    height: 100%;
    background-color: var(--wb-gray-100);
}

.timelinerow[data-type='active'] .line {
    background-color: var(--wb-blue-700);
}

.timelinerow[data-type='future'] .line {
    background-color: var(--wb-gray-100);
}

.iconWrapper {
    position: relative;
    z-index: 3;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--default-secondary);
    color: var(--wb-base-white);
}

.iconWrapper .material-icons {
    font-size: 1.5rem;
}

.timelinerow[data-type='active'] .iconWrapper {
    background-color: var(--wb-base-white);
    color: var(--default-secondary);
    border: 2px solid var(--default-secondary);
}

.timelinerow[data-type='future'] .iconWrapper {
    background-color: var(--wb-gray-100);
    color: var(--wb-gray-400);
    border: 2px solid var(--wb-gray-200);
}

@media (min-width: 768px) {
    .timelinerow {
        gap: 0;
    }

    .timelinerow:nth-child(even) {
        flex-direction: row;
        text-align: right;
    }

    .timelineinfobox {
        flex: 0 0 40%;
    }

    .timelineiconbox {
        flex: 0 0 20%;
    }
}

@media (min-width: 1280px) {
    .timelineinfobox {
        flex: 0 0 45%;
    }

    .timelineiconbox {
        flex: 0 0 10%;
    }
}
