.buttonBlock {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.btn-center {
    justify-content: center;
}

.btn-right {
    justify-content: end;
}
