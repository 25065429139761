.CardBlock {
    display: block;
    padding: var(--skeleton-block-default-padding);
}

.CardBlock__layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -1rem;
}

.CardBlock__inner {
    margin-bottom: 2rem;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.horizontal {
    flex: 0 0 100%;
}

.CardItem {
    padding: 0 1rem;
    margin-bottom: 2rem;
    flex: 0 0 100%;
}

@media (min-width: 768px) {
    .CardItem {
        flex: 0 0 50%;
    }

    .horizontal {
        flex: 0 0 100%;
    }
}

@media (min-width: 1024px) {
    .default,
    .basic {
        flex: 0 0 33.3%;
    }

    .default_4,
    .basic_4 {
        flex: 0 0 25%;
    }
}
