.InlinePortalBlockContainer {
    padding-top: 0;
    padding-bottom: 0;
}

.InlinePortalBlockWrapper {
    position: relative;
    display: block;
    height: 500px;
    width: 100%;
}

.InlinePortalBlock {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.webmap__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
}
